import { omdApi } from 'store/api';

import { AcquiringInfo, GenerateLinkForPayment, PaymentRequest, PaymentStatus } from '../Payment.entity';

const request = omdApi.injectEndpoints({
  endpoints: (builder) => ({
    paymentInfo: builder.query<AcquiringInfo, PaymentRequest>({
      query: (query) => ({
        url: `/v1/payments/link/${query.accountId}`,
        params: query,
      }),
    }),
    paymentGenerateLink: builder.mutation<string, GenerateLinkForPayment>({
      query: (data) => ({
        method: 'POST',
        url: '/v1/payments/generate-link-for-payment',
        body: data,
      }),
    }),
    paymentStatus: builder.query<PaymentStatus, { id: string | undefined }>({
      query: (query) => ({
        url: `/v1/payments/${query.id}`,
      }),
    }),
  }),
});

export const { usePaymentInfoQuery, usePaymentStatusQuery, usePaymentGenerateLinkMutation } = request;
